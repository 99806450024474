

$width-tablette: 1200px;
$width-tab-mob: 1024px;
$width-mobile: 960px;
$width-mobile-min: 760px;
$width-ordi: 768px;

@mixin tablette {
  @media screen and (min-width: #{$width-tablette}){
    @content
  }
};

@mixin mobile {
  
  @media screen and (max-width: #{$width-mobile}){
    @content
  }
};
@mixin mobile-min {
  
  @media screen and (max-width: #{$width-mobile}){
    @content
  }
};

@mixin tab-mob {
  
  @media screen and (min-width: #{$width-tab-mob}){
    @content
  }
};
@mixin ordi {
  
  @media screen and (max-width: #{$width-ordi}){
    @content
  }
}