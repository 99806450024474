@import '../../global.scss';


.livres{
  box-sizing: border-box; 
  background: #dcdce7e8;
  
  h1{
    text-align: center;
    font-size: 3rem;
    color: #333;
    justify-content: center;
    padding-top: 10px;
    @include mobile{
      font-size: 2rem;
      // background: #434346e8;
      padding-bottom: 50px;

    }

  }
  .search{
    padding: 0px;
    position: absolute;
    right: 52px;
    top: 80px;
    z-index: 2;
    input{
      border-radius: 20px 0 0 20px;
    }
    .searchIcon{
      border-radius: 0 20px 20px 0;
    }
    @include mobile{
      padding: 0;
      margin: 5px 10px ;
      right: 45px;
      top: 110px;
      // position:initial;
    }

  }
  .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0;

      .card {
        position: relative;
        // width: 300px;
        // height: 300px;
        width: 100%;
        height: 110px;
        align-items: center;
        justify-content: center;
        box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
                    inset 5px 5px 15px rgba(255, 255, 255, 0.1),
                    5px 5px 15px rgba(0, 0, 0, 0.3), 5px 55px 15px rgba(255, 255, 255, 0.1);
        // border-radius: 15px;
        // margin: 15px;
        transition: 0.5s;
        background: #dbdbdbe5;

        @include mobile{
          padding: 0;
          margin: 0;
          border-radius: 0;
          width: 100%;
          height: 110px;
        }
        .box {
            position: absolute;
            top: 10px;
            left: 15px;
            right: 15px;
            bottom: 10px;
            background: #f5f5f5;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: 0.7s;
            @include mobile{
              padding: 0;
              margin: 5px 2px;
              justify-content: flex-start;
              border-radius: 0;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }
            &:hover {
              transform: translateY(-10px);
            }
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              background: rgba(241, 241, 241, 0.03);
            }
            .content {
                padding: 10px;
                text-align: center;
                @include mobile{
                  text-align: start;                
                  Button{
                    font-size: 10px;
                  }

                }
                Button{
                  // font-size: 50px;
                  color : #333;
                  // border-color: #333;
                  border: none
                }


                h2 {
                    position: absolute;
                    top: 20px;
                    right: 10px;
                    font-size: 2rem;
                    color: rgba(165, 163, 163, 0.644);
                    @include mobile{
                      font-size: 1.5rem;
                      top: 5%;
                      right: 10px;

                    }
                  }

                h3 {
                    font-size: 1.2rem;
                    color: #333;
                    z-index: 1;
                    transition: 0.5s;
                    margin-bottom: 5px;
                    @include mobile{
                      font-size: 1rem;

                    }
                  }

                p {
                    font-size: 1rem;
                    font-weight: 300;
                    color: #333;
                    z-index: 1;
                    transition: 0.5s;
                    margin-bottom: 5px;

                    @include mobile{
                      display: none;
                    }
                  }

                a {
                    position: absolute;
                    top : 72%;
                    right: 29%;
                    padding: 2px 5px;
                    // background: black;
                    border-radius: 5px;
                    text-decoration: none;
                    // color: rgba(255, 255, 255, 0.849);
                    margin-top: 20px;
                    color: #000;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
                    transition: 0.5s;
                    &:hover {
                        box-shadow: 0 10px 20px rgba(61, 59, 59, 0.37);
                        background: #ffffffb4;
                        color: #333;
                      }
                    @include mobile{
                      position:absolute;
                      top : 40%;
                      right: 9%;

                    }
                  }
              }

          }
          
          

      }
    } 
}

  
  

// // body .container .card:nth-child(1) .box .content a {
// //   background: #2196f3;
// // }

// // body .container .card:nth-child(2) .box .content a {
// //   background: #e91e63;
// // }

// // body .container .card:nth-child(3) .box .content a {
// //   background: #23c186;
// // }

// body .container .card .box .content a:hover {
//   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
//   background: #fff;
//   color: #000;
// }
