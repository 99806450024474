@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
@import  './global.scss';
/* .App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  background-image: linear-gradient(90deg, #410076, #8820dd);
}
body {
  padding: 0;
  margin: 0;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.about,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #fff;
  font-size: 100px;
  @include mobile{
    font-size: 50px;
  }
}

.Docs {
  height: 90vh;
  align-items: center;
  justify-content: center;
  position : relative; 
  // left : 20%
  .cofama {
    width: 99vw;
    height: 90vh;
  }
  
  // .Document{
  //     margin: 0 auto;
  // }

  // p{
  //   position: absolute;
  //   left: 45%;
  //   top: 97%;
  // }
}