@import '../../global.scss';
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  @include mobile{
    position: relative;
  }
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;

  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 100px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    font-size: 2rem;
    display: flex;
    align-items: center;
    @include mobile{
      position: absolute;
      margin-left: 0px;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }

    .fa-typo3 {
      margin-left: 0.5rem;
      font-size: 1.8rem;
    }
  }

  .menu-icon {
    display: none;
    @include mobile{
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }

    .fa-bars {
      color: #fff;
      @include mobile{
        font-size: 2rem;
      }
    }

    .fa-times{
      @include mobile{
        color: #fff;
        font-size: 2rem;
      }
    }
  }
  
  .nav-menu.active{
    @include mobile{
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    }
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(10, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 90vw;
    justify-content: end;
    margin-right: 2rem;
    @include mobile{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-item {
      height: 80px;
      &:last-child{
        padding-top: 20px;
      }
      
      .nav-links {
        color: #fff;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0.2rem 1rem;
        height: 95%;
      
        &:hover {
          border-bottom: 4px solid #fff;
          transition: all 0.2s ease-out;
        }
        @include mobile{
          text-align: center;
          padding: 2rem;
          width: 100%;
          display: table;
          &:hover {
            background-color: #fff;
            color: #242424;
            border-radius: 0;
          }
        }
      }

      .nav-links-mobile {
        display: none;
        @include mobile{
          display: block;
          text-align: center;
          margin: 1.2rem auto;
          border-radius: 4px;
          width: 80%;
          text-decoration: none;
          font-size: 1rem;
          background-color: transparent;
          color: #fff;
          padding: 14px 10px;
          border: 1px solid #fff;
          transition: all 0.3s ease-out;
          &:hover {
            background: #fff;
            color: #242424;
            transition: 250ms;
          }
        }
      }

    }
    
  }

}










// @media screen and (max-width: 960px) {
//   .NavbarItems {
//     position: relative;
//   }

// }
