@import '../../global.scss';
.article{
  justify-content: center;
  box-sizing: border-box; 
  // background: #f5f5f5;
  background-size:initial;
  padding: 0;
  margin: 0;
  height: 80vh;
  .card {
    margin: 0 auto; 
    // margin-top: 90px;
    padding-top: 30px;
    // background-color: rgba(73, 72, 72, 0.263);
    background: #f5f5f5;
    width: 800px;
    min-height: 90vh;
    max-height: fit-content;
    height: content;
    border-radius: 15px;
    -webkit-box-shadow: -1px -1px 14px 5px rgba(148, 147, 147, 0.86);
    box-shadow: -1px -1px 14px 5px rgba(148, 147, 147, 0.86);
    // position: absolute;
    // left: 40%;
    @include mobile {
      // margin-top: 120px;
      width: 90%;
      // height:500px;
      max-height: fit-content;
      // left: 10%;
    }    
    h3{
      text-align: center;
      font-size: 1rem;
      justify-content: center;
      color: #333;
      padding: 15px 80px;
      @include mobile{
        font-size: 0.8rem;

      }

    }
    h1{
      text-align: center;
      font-size: 3rem;
      justify-content: center;
      color: #333;
      padding-top: 15px;
      @include mobile{
        font-size: 2rem;

      }

    }

    .content{
      color: #333;
      text-align: justify;
      white-space: pre-wrap;
      font-size: 1.2rem;
      line-height: 40px;
      padding: 50px 50px;
      @include mobile{
        padding: 30px 5px;
        padding-bottom: 80px;
        font-size: 1rem;
        line-height: 20px;

      }
    }
    .btns{
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      padding-bottom: 50px;

      
        a {
          text-decoration: none;
          color: #333;
          background-color: transparent;
          padding: 0px;
          &:hover{
            // background-color: #555;
            color: #fff;
          }

          button{
            color: #333;
            border-color: #333;
            margin: 25px 40px;
            cursor: pointer;
            &:hover{
                background-color: #555;
                color: #fff;
            }
            @include mobile{
              margin: 5px;
              font-size: 10px;
              padding: 5px 10px;
              width: 100px;
            }

          }

        

      }
    }

  }
  
}

.articles {
  box-sizing: border-box; 
  background: #434346e8; 
  h1{
    text-align: center;
    font-size: 2rem;
    justify-content: center;
    color: #fff;
    padding-top: 15px;
    @include mobile{
      font-size: 2rem;
      background: #434346e8;

    }

  }
  .search{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .search-input{
      padding: 8px 20px;
      border-radius: 5px;
      margin-top: 10px;
      // margin: 5px 750px;
      width: 400px;
      outline: none;
      border: none;
      font-size: 18px;
      margin-bottom: 16px;
      border: 1px solid #fff;
      @include mobile{
      width: 100%;
      margin: 5px 100px;
    }  
    &::placeholder{
      color: #b1b1b1;
      font-size: 15px;
      @include mobile{
        font-size: 12px;
      } 
    }

    }

    
  }
  .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0;

      .card {
        position: relative;
        // width: 300px;
        // height: 300px;
        width: 100%;
        height: 110px;
        align-items: center;
        justify-content: center;
        box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
                    inset 5px 5px 15px rgba(255, 255, 255, 0.1),
                    5px 5px 15px rgba(0, 0, 0, 0.3), 5px 55px 15px rgba(255, 255, 255, 0.1);
        // border-radius: 15px;
        // margin: 15px;
        transition: 0.5s;
        background: #dbdbdbe5;

        @include mobile{
          padding: 0;
          margin: 0;
          border-radius: 0;
          width: 100%;
          height: 110px;
        }
        .box {
            position: absolute;
            top: 10px;
            left: 15px;
            right: 15px;
            bottom: 10px;
            background: #f5f5f5;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: 0.7s;
            @include mobile{
              padding: 0;
              margin: 5px 2px;
              justify-content: flex-start;
              border-radius: 0;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }
            &:hover {
              transform: translateY(-10px);
            }
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 50%;
              height: 100%;
              background: rgba(241, 241, 241, 0.03);
            }
            .content {
                padding: 10px;
                text-align: center;
                @include mobile{
                  text-align: start;                
                  Button{
                    font-size: 10px;
                  }

                }
                Button{
                  // font-size: 50px;
                  color : #333;
                  // border-color: #333;
                  border: none
                }


                h2 {
                    position: absolute;
                    top: 20px;
                    right: 10px;
                    font-size: 2rem;
                    color: rgba(165, 163, 163, 0.644);
                    @include mobile{
                      font-size: 1.5rem;
                      top: 5%;
                      right: 10px;

                    }
                  }

                h3 {
                    font-size: 1.2rem;
                    color: #333;
                    z-index: 1;
                    transition: 0.5s;
                    margin-bottom: 5px;
                    @include mobile{
                      font-size: 1rem;

                    }
                  }

                p {
                    font-size: 1rem;
                    font-weight: 300;
                    color: #333;
                    z-index: 1;
                    transition: 0.5s;
                    margin-bottom: 5px;

                    @include mobile{
                      display: none;
                    }
                  }

                a {
                    position: absolute;
                    top : 72%;
                    right: 29%;
                    padding: 2px 5px;
                    // background: black;
                    border-radius: 5px;
                    text-decoration: none;
                    // color: rgba(255, 255, 255, 0.849);
                    margin-top: 20px;
                    color: #000;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
                    transition: 0.5s;
                    &:hover {
                        box-shadow: 0 10px 20px rgba(61, 59, 59, 0.37);
                        background: #ffffffb4;
                        color: #333;
                      }
                    @include mobile{
                      position:absolute;
                      top : 40%;
                      right: 9%;

                    }
                  }
              }

          }
          
          

      }
    } 
}