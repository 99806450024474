@import '../../global.scss';

.search{

    .searchInputs{
        margin-top: 20px;
        display:flex;
        z-index: 4;
        
        input{
            background-color:white;
            border: 0;
            border-radius:2px;
            border-top-right-radius: 0px;
            border-bottom-left-radius: 0px;
            font-size:18px;
            padding: 20px;
            height: 30px;
            width: 300px;

            @include mobile{
                margin-left: 5%;
                width : 100%;
            }

            &:focus{
                outline:none;
            }
        }

        .searchIcon{
            height: 40px;
            width: 50px;
            background-color: white;
            display: grid;
            place-items: center;

            svg{
                font-size: 30px;
            }   
            #clearBtn {
                cursor: pointer;
            }
        }

    }
    .dataResult{
        margin-top: 5px;
        width: 300px;
        height: 200px;
        position: absolute;
        z-index: 5;
        background-color: white;
        box-shadow:rgba(0, 0, 0, 0.35) 0px 5px 15px;
        overflow: hidden;
        overflow-y: auto;

        @include mobile{
            margin-left: 5%;
            width : 95%;
        }
        
        &::-webkit-scrollbar {
            display: none;
          }

        .dataItem {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            color: black;

            p {
                margin-left: 10px;
            }
        }

    }
        
    a {
        text-decoration: none;

        &:hover {
            background-color: lightgrey;
          }
    }

    .notfund{
        padding-left: 15%;
        font-size: 17px;
        border-radius: 5px;
        color: crimson;
        background-image: linear-gradient(90deg, #d0cdd3, #8e8e8f79);
    }

}