:root {
  --primary : #fff;
}

.btn{
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor:pointer;
}

.btn--primary{
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline{
  background-color:transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out; 

  &:hover{
      color: #242424;
      background-color: #fff;
    
  }
}


.btn-meduim {
  padding: 8px 20px;
  font-size: 20px;

  &:hover{
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out; 
  }
}

.btn--large{
  padding: 8px 26px;
  font-size: 20px;  
  
  &:hover{
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out; 
  }
}
