@import '../../global.scss';

.sign {
  background-position: 'center';
  background-size: 'cover'; 
  background-repeat: 'no-repeat';
  height: 800px;
  .login-page{
    margin-top: 90px;
    padding-top: 70px;
    background-color: rgba(73, 72, 72, 0.263);
    width: 400px;
    height:400px;
    border-radius: 15px;
    -webkit-box-shadow: -1px -1px 14px 5px rgba(148, 147, 147, 0.86);
    box-shadow: -1px -1px 14px 5px rgba(148, 147, 147, 0.86);
    position: absolute;
    left: 40%;

    @include mobile {
      margin-top: 120px;
      width: 300px;
      height:360px;
      left: 10%;
    }


    h1 {
      padding: 2%;
      text-align: center;
      color: white;
    }

    p{
      color: white;
      align-items:center;
      text-align: center;

      &:last-child{
        font-size: 12px;
      }
    }


  }
}