@import "../../global.scss";

.cards {
  padding: 4rem;
  background: #fff;

  h1 {
    text-align: center;
    font-size: 3rem;
    @include mobile{
      font-size: 2rem;

    }
  }

  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;

    .cards__wrapper {
      position: relative;
      margin: 50px 0 45px;

      .cards__items {
        margin-bottom: 24px;

        @include tab-mob {
            display: flex;
        }


      }


    }


  }
}


.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;

  @include ordi {
    margin-bottom: 2rem;
  }

  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    
    .cards__item__pic-wrap {
      position: relative;
      width: 100%;
      padding-top: 67%;
      overflow: hidden;
      
      &::after {
        content: attr(data-category);
        position: absolute;
        bottom: 0;
        margin-left: 10px;
        padding: 6px 8px;
        max-width: calc((100%) - 60px);
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #1f98f4;
        box-sizing: border-box;
      }



      .cards__item__img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: all 0.2s linear;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .cards__item__info {
      padding: 20px 30px;
    
      .cards__item__text {
        // color: #252e48;
        text-align: center;
        justify-content: center;
        font-size: 18px;
        line-height: 24px;
        @include mobile{
          font-size: 12px;
          }

        
      }
    }

  }


}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}


@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

