@import '../../global.scss';



.hero-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0px 0px 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    @include mobile{ 
        padding: 80px 5px;
        background-position: absolute;
        background-size: cover;
        background-height: 100vh;
        background-position-x: center right;
        background-color: rgba(0, 0, 0, 0.603);
    }

    h1 {
        color : #fff;
        font-size : 70px;
        margin-top:180px;
        margin-left: 20px;
        @include mobile{
            font-size:70px;
            margin-top: 90px;
        }
        @include mobile-min {
            font-size:50px;
            margin-top: 45px;
        }
    }
    
    >p {
        color : #fff;
        font-size: 32px;
        margin-top: 8px;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        @include mobile-min{
            font-size: 20px;
        }
    }
    
    .hero-btns {
        margin-top: 42px;
        
        .btn { 
            margin: 6px;
            @include mobile-min {
                width: 100%;
            }
            
            .fa-play-circle {
                margin-left: 4px;
            }
        }
    }

}


// @media screen and (max-width: 760px) {
//     .btn-mobile {
//         display: block;
//         text-decoration: none;
//     }
// }