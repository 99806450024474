@import '../../global.scss';

.create-article{
    
  .form-add{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: rgba(73, 72, 72, 0.263);
    width: 600px;
    height:650px;
    border-radius: 15px;
    -webkit-box-shadow: -1px -1px 14px 5px rgba(148, 147, 147, 0.86);
    box-shadow: -1px -1px 14px 5px rgba(148, 147, 147, 0.86);
    @include mobile{
      width: 350px;
      height:500px;
      margin-top: 100px;
    }
    
    form{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90%;

      .form-item{
        display: flex;
        justify-content: space-between;
        padding: 4px 20px;
        margin: 2px 20px;
        align-items: first baseline;
        @include mobile {
          justify-content: flex-end;
          flex-direction: row;
          margin: 2px 0px;
        }

        input, textarea{
          padding: 5px 20px;
          vertical-align: text-bottom;
          width: 60%;
          border-radius: 5px;
          margin-right: 10px;
          outline: none;
          border: none;
          font-size: 18px;
          margin-bottom: 5px;
          border: 1px solid rgb(209, 208, 208);
          background-color: rgba(243, 239, 239, 0.719);
          transition: all 0.5s ease; 
          @include mobile{
          width: 200px;
          margin-right: 0px;
          margin-left: 10px;
          font-size: 10px;
          }

          &::placeholder{
            color: #797777;
            font-size: 15px;
            @include mobile {
              font-size: 11px;
            }
          }

          &:focus{ 
            transition: all 1s ease; 
            &::placeholder{
              font-size: 12px;
              justify-content: flex-end;
            }
          }
        }

        label{
          color: #fff;
          text-align: center;
          justify-content:center;
          font-size: 15px;
        }
        
      }
      .submit{
        margin-top: 15px;
        margin-left: 450px;
        @include mobile{
          margin: 10px 100px;
        }
        button{
          @include mobile {
            font-size: 13px;
          }
        }
        }

    }

    h1 {
      padding: 5%;
      text-align: center;
      color: white;
      @include mobile{
        font-size: 25px;
      }
    }

  }


}